.breadcrumbs a:not(:first-child):before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
  width: 7px;
  height: 7px;
  margin: auto 0;
  transform: rotate(45deg);
  border: solid;
  border-color: initial;
  opacity: 0.3;
  border-width: 1px 1px 0 0;
}

.breadcrumbs a {
  @apply relative inline-block pl-4 mr-1 first:pl-0;
}

.breadcrumbs {
  @apply mb-4;
}

.breadcrumbs a:hover:before {
  color: black;
}

header .breadcrumbs a:hover:before {
  @apply text-white;
}

@media (min-width: 640px) {
  .breadcrumbs a:not(:first-child):before {
    top: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
  }
}
